const API_URL = {
  USERS: "users",
  USERS__LOGIN: "users/login",
  USERS__REFRESH: "users/refresh",
  USERS__LOGOUT: "users/logout",
  USERS__AVAILABLE_CONTRIBUTIONS: "users/available-contributions",
  USERS__RANK__COLLECTION: "users/rank/collection",
  USERS__RANK__CONTRIBUTIONS: "users/rank/contributions",
  USERS__PROFILE: "users/profile",
  USERS__REFERRED_COUNT: "users/referred-count",
  MON_IMAGES: "mon-images",
  MONS: "mons",
  MONS__WITH_IMAGES: "mons/with-images",
  MONS__ACTIVE: "mons/active",
  MONS__INACTIVE: "mons/inactive",
  MONS__NEXT: "mons/:monId/next",
  MONS__RECENT: "mons/recent",
  COLLECTIONS: "collections",
  COLLECTIONS__HUNT: "collections/hunt",
  COLLECTIONS__USER: "collections/user",
  COLLECTIONS__EVOLVE: "collections/evolve",
  COLLECTIONS__BLEND: "collections/blend",
  COLLECTIONS__RANK: "collections/rank",
  COLLECTIONS__PROFILE: "collections/profile",
  PAINTINGS: "paintings",
  LIKES: "likes",
  LIKES_UNLIKE: "likes/unlike",
  PAYBACKS: "paybacks",
  PAYBACKS__HISTORY: "paybacks/history",
  PAYBACKS__LAST: "paybacks/last",
  RARE_NEWS__RECENT: "rare-news/recent",
  CONTENTS: "contents",
  CONTENTS__VIEW: "contents/view",
  CONTENTS__FILE: "contents/file",
  COMMENTS: "comments",
};

export default API_URL;
